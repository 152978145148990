<template>
  <div class="row">
    <!-- Big Chart -->
    <div class="col-12">
      <card type="chart">
        <template slot="header">
          <div class="row">
            <div class="col-sm-6 text-left">
              <h5 class="card-category">Total de créditos comprados</h5>
              <h2 class="card-title">Créditos comprados</h2>
            </div>
            <div class="col-sm-6 d-flex d-sm-block"></div>
          </div>
        </template>
        <div class="chart-area">
          <line-chart
            style="height: 100%"
            ref="bigChart"
            :chart-data="bigLineChart.chartData"
            :gradient-colors="bigLineChart.gradientColors"
            :gradient-stops="bigLineChart.gradientStops"
            :extra-options="bigLineChart.extraOptions"
          >
          </line-chart>
        </div>
      </card>
    </div>
    <!-- Stats Cards -->
    <div class="col-lg-4 col-md-6">
      <stats-card
        :title="cards.colets_maked.toFixed(0)"
        sub-title="Coletas Realizadas"
        type="primary"
        icon="fas fa-truck"
      >
        <!-- <div slot="footer" v-html="''"></div> -->
      </stats-card>
    </div>

    <div class="col-lg-4 col-md-6">
      <stats-card
        :title="cards.colets_pending.toFixed(0)"
        sub-title="Coletas Pendentes"
        type="warning"
        icon="fas fa-truck"
      >
        <!-- <div slot="footer" v-html="''"></div> -->
      </stats-card>
    </div>

    <div class="col-lg-4 col-md-6">
      <stats-card
        :title="`${ cards.clients }`"
        sub-title="Clientes Cadastrados"
        type="primary"
        icon="fas fa-user"
      >
        <!-- <div slot="footer" v-html="''"></div> -->
      </stats-card>
    </div>

    <div class="col-lg-6 col-md-6">
      <stats-card
        :title="`R$ ${cards.buies.toFixed(2)}`"
        sub-title="Compras Realizadas"
        type="primary"
        icon="fas fa-shopping-cart"
      >
        <!-- <div slot="footer" v-html="''"></div> -->
      </stats-card>
    </div>

    <div class="col-lg-6 col-md-6">
      <stats-card
        :title="`R$ ${cards.income.toFixed(2)}`"
        sub-title="Receitas"
        type="primary"
        icon="fas fa-wallet"
      >
        <!-- <div slot="footer" v-html="''"></div> -->
      </stats-card>
    </div>

    <!-- statsCards: [{
                    title: '150GB',
                    subTitle: 'Number',
                    type: 'warning',
                    icon: 'tim-icons icon-chat-33',
                    footer: '<i class="tim-icons icon-refresh-01"></i> Update Now'
                },
                {
                    title: '+45K',
                    subTitle: 'Followers',
                    type: 'primary',
                    icon: 'tim-icons icon-shape-star',
                    footer: '<i class="tim-icons icon-sound-wave"></i></i> Last Research'
                },
                {
                    title: '150,000',
                    subTitle: 'Users',
                    type: 'info',
                    icon: 'tim-icons icon-single-02',
                    footer: '<i class="tim-icons icon-trophy"></i> Customer feedback'
                },
                {
                    title: '23',
                    subTitle: 'Errors',
                    type: 'danger',
                    icon: 'tim-icons icon-molecule-40',
                    footer: '<i class="tim-icons icon-watch-time"></i> In the last hours'
                }
            ] -->
  </div>
</template>
<script>
import LineChart from '@/components/Charts/LineChart'
import * as chartConfigs from '@/components/Charts/config'
import StatsCard from '@/components/Cards/StatsCard'
import config from '@/config'

export default {
  components: {
    LineChart,
    StatsCard
  },
  data: () => ({
    statsCards: [],
    bigLineChart: {
      activeIndex: 0,
      chartData: null,
      extraOptions: chartConfigs.purpleChartOptions,
      gradientColors: config.colors.primaryGradient,
      gradientStops: [1, 0.4, 0],
      categories: []
    },
    chartData: [],
    chartLabels: [],
    cards: {
      colets_maked: 0,
      colets_pending: 0,
      income: 0,
      buies: 0,
      clients: 0
    }
  }),

  computed: {
    enableRTL () {
      return this.$route.query.enableRTL
    },
    isRTL () {
      return this.$rtl.isRTL
    },
    bigLineChartCategories () {
      return [
        {
          name: 'Creditos',
          icon: 'tim-icons icon-single-02'
        }
      ]
    }
  },
  methods: {
    initBigChart (index) {
      const chartData = {
        datasets: [
          {
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.chartData
          }
        ],
        labels: this.chartLabels
      }

      this.$refs.bigChart.updateGradients(chartData)
      this.bigLineChart.chartData = chartData
      this.bigLineChart.activeIndex = index
    },
    async getData () {
      const data = (await this.$http.get('dashboard')).data

      this.chartData = data.deposits_grapy ? data.deposits_grapy.data : []
      this.chartLabels = data.deposits_grapy ? data.deposits_grapy.labels : []

      this.cards.colets_maked = data.colets_maked
      this.cards.colets_pending = data.colets_pending
      this.cards.income = data.income
      this.cards.clients = data.clients

      //  esses aqui sao os itens do dash

      // const t = {
      //   title: '23',
      //   subTitle: 'Coletas Realizadas',
      //   type: 'danger',
      //   icon: 'tim-icons icon-molecule-40',
      //   footer: '<i class="tim-icons icon-watch-time"></i> Total'
      // }
    }
  },
  async mounted () {
    this.i18n = this.$i18n
    await this.getData()
    this.initBigChart(0)
  },
  beforeDestroy () {
    if (this.$rtl.isRTL) {
      this.i18n.locale = 'en'
      this.$rtl.disableRTL()
    }
  }
}
</script>
